export const environment = {
  api: 'https://api3.karaokesmart.co/v2',  
  apiExtraHeaders: { headers: { 'X-API-Version': '2.1' } },
  production: true,
  debug: false,
  defaultConfig: 'config',
  webDomains: {
    default: 'kanto.co',
    global: 'kanto.global',
    ES: 'kanto.es'
  },
  firebase: {
    apiKey: 'AIzaSyCm5yKV8Ca9AkUfQ177qglfOew80RETMzU',
    authDomain: 'green-gasket-592.firebaseapp.com',
    databaseURL: 'https://green-gasket-592.firebaseio.com',
    functionsURL: 'https://us-central1-green-gasket-592.cloudfunctions.net',
    projectId: 'green-gasket-592',
    storageBucket: 'green-gasket-592.appspot.com',
    messagingSenderId: '666515694770',
    appId: '1:666515694770:web:6eded1d990e06354f015ab',
    measurementId: 'G-QW1XDY22SC',
  },
  fbAppId: '225926810886925',
  mixpanel: {
    token: '1f416976fad5e69f5a9d45980af08f24',
  },
  sms: {
    api_key: 'b9e1f7e4ca74666dbc6590fc5b3c4878',
  },
  vizio:{
    appKey: 'kanto_karaoke-d4c593b9f2a7'
  },
  spreedly: {
    user: 'I9mJtrSk6U6aEaRGeVVOUYQxCwL',
    pass: 'KsI4FHhX05L5Afaxp8OaGMi7xD9SHiCEiCIDODkLMdRx1F6Kr7h0xXie2LaKbid4',
  },
  gateways: {
    conekta: {
      token: 'key_TsNg7nBSXE7eHxTDuyq3uvA',
    },
    ebanx: {
      integration_key: 'live_ik_uhKNVyRwpQuA4yUoZFPlqw',
      public_key: 'live_pk_JpfYDtkt93lLlaQpvvrBXw',
      url: 'https://api.ebanxpay.com/ws/token',
    },
    payu: {
      accountID: {
        default: '733858',
        CO: '733858',
        MX: '733853',
        PE: '628977',
      },
      public_key: 'PKl6f37fy32qC9J0o56EtGm7ci',
      url: 'https://api.payulatam.com/payments-api/4.0/service',
    },
  },
  buildNumber: '202512120312',
  version: '15.7.4',
};
